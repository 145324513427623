<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="fixed inset-0 overflow-hidden z-50" @close="open = false" :open="open">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-medium text-gray-900">
                        {{$t('installation.shareInstallation')}}
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ifgreen" @click="open = false">
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="mt-6 relative flex-1 px-4 sm:px-6">
                    <p class="text-sm font-medium text-gray-900">{{ currentInstallation.customerName }}</p>
                    <p class="text-sm">{{ currentInstallation.customerAddress }}, {{ currentInstallation.customerCity }}</p>
                    <p class="mb-4 text-sm">{{ new Date(currentInstallation.installationDate).toLocaleDateString('pl-PL') }}</p>
                    <p class="text-xs mb-6">{{$t("installation.installationShareInfo")}}</p>
                    <div>
                      <UserSelect v-model="selectedPerson" :label="$t('installation.selectUser')" :people="people" />
                    </div>
                    <div class="space-y-1" v-if="currentInstallation.receivers.length">
                      <p class='text-sm font-medium mt-8'>{{$t('installation.installationSharedInfo')}}: </p>
                      <div v-for="(receiver, index) in currentInstallation.receivers" :key="receiver.id" class="flex justify-between items-center">
                        <p>{{ receiver.company }}</p>
                        <p v-if="user.asp && user.asp.id === receiver.id">(APS)</p>
                        <button v-else :title="$t('installation.pauseSharing')" @click="deleteSharing(receiver.id, index)" class="rounded-full flex items-center bg-ifgreen text-white px-1 py-1 hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ifgreen">
                          <CogIcon v-if="loading === index" class="h-5 w-5 animate-spin" aria-hidden="true" />
                          <TrashIcon v-else class="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen" @click="open = false">
                    {{$t('cancel')}}
                  </button>
                  <button :disabled="!this.selectedPerson" @click="submitInstallation" type="submit" :class="!this.selectedPerson ? 'pointer-events-none opacity-60' : ''" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
                    {{$t("installation.share")}}
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { XMarkIcon, TrashIcon, CogIcon } from '@heroicons/vue/24/outline'
import UserSelect from '@/components/inputs/UserSelect.vue'
import { api, patchHeaders } from "@/assets/js/api-client.js";
import { mapState } from 'vuex'

export default {
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        XMarkIcon,
        TrashIcon,
        CogIcon,
        UserSelect
    },
    data () {
      return {
        open: false,
        selectedPerson: null,
        people: null,
        loading: -1,
      }
    },
    computed: {
      ...mapState(['user', 'role', 'installations', 'currentInstallation', 'organisation', 'aspView'])
    },
    methods: {
        show () {
            this.open = true
            if (!this.people) this.getUsers()
        },
        getUsers() {
          api.get(`/organisations/${this.organisation}/users?order[company]`).then(result => {
            this.people = result.data
              .filter(user => {return user.id != this.user.id})
              .map(user => {return { id: user.id, company: user.company, email: user.email }})
            console.log(this.people)
          }).catch(err => {
            console.log(err);
          })
        },
        async refreshInstallation () {
          let status = this.currentInstallation.status
          if (this.role.name == 'installer' || (this.role.name == 'ASP' && !this.aspView)) {
            status = null
          }

          await this.$store.dispatch('getCollection', {entity: "installations", status})
          this.loading = -1
          this.$store.dispatch('setLoading', false)
        },
        submitInstallation () {
          this.open = false
          this.$nextTick(() => {
            this.$store.dispatch('setLoading', true)
          })

          let receivers = this.currentInstallation.receivers.map(user => {return `/users/${user.id}`})
          receivers.push(`/users/${this.selectedPerson.id}`)

          const data = { receivers }

          api.patch(`/installations/${this.currentInstallation.id}`, data, patchHeaders).then(() => {
            this.refreshInstallation()
          }).catch(err => {
            console.log(err);
            this.$store.dispatch('setLoading', false)
          })
        },
        deleteSharing (id, index) {
          this.loading = index

          let receivers = this.currentInstallation.receivers
            .filter(user => {return user.id !== id})

          const data = { receivers: receivers.map(user => {return `/users/${user.id}`}) }

          api.patch(`/installations/${this.currentInstallation.id}`, data, patchHeaders).then(() => {
            this.refreshInstallation()
            this.currentInstallation.receivers = receivers
            this.$store.dispatch('saveCurrentInstallation', this.currentInstallation)
          }).catch(err => {
            console.log(err);
          })
        }
    },
}
</script>
